<template lang="html">

  <section class="footer container">
    <div class="list-footer">
      <a href="https://www.aldonzagourmet.com/aviso-legal" target="_blank">Aviso legal</a>
      <a href="https://www.aldonzagourmet.com/datos-seguros" target="_blank">Datos seguros</a>
      <a href="https://www.aldonzagourmet.com/politica-de-proteccion-de-datos" target="_blank">Política de protección de datos</a>
      <a href="https://www.aldonzagourmet.com/inicio/contacto" target="_blank">Contacto</a>

    </div>
    <div class="rrss row-center">
      <a target="_blank" href="https://www.facebook.com/pages/Aldonza-Gourmet/438637442941023?fref=ts"><i class="fa fa-facebook"></i></a>
      <a target="_blank" href="https://twitter.com/AldonzaGourmet"><i class="fa fa-twitter"></i></a>
      <a target="_blank" href="https://www.instagram.com/aldonzagourmet/"><i class="fa fa-instagram"></i></a>
      <a target="_blank" href="https://www.youtube.com/channel/UCbgjP5f05GQqAeg8b2O5xMQ"><i class="fa fa-youtube"></i></a>
      <a target="_blank" href="https://www.tripadvisor.es/Attraction_Review-g1437533-d15780377-Reviews-Bodegas_Aldonza-Munera_Province_of_Albacete_Castile_La_Mancha.html"><i class="fa fa-tripadvisor"></i></a>
    </div>
  </section>

</template>

<script lang="js">

  export default {
    name: 'footer',
    props: [],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 20px;

    .list-footer {
      width: calc(100% - 300px);

      a {
        color: #4d161f;
        text-decoration: none;
        margin-right: 30px;
      }
    }

    .rrss {
      width: 300px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;

      a {
        border-radius: 50%;
        color: #4d161f;
        border: 1px solid #4d161f;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin-left: 10px;
      }

    }
  }

  @media screen and (max-width:1000px) {
    .footer .list-footer {
      width: calc(100% - 200px);
    }

    .footer .rrss {
      width: 200px;
    }
  }

  @media screen and (max-width:880px) {
    .footer .list-footer {
      width: 100%;
      justify-content: center;
      margin-bottom: 15px;
      display: flex;
    }

    .footer .rrss {
      width: 100%;
      justify-content: center;

    }
  }

  @media screen and (max-width:750px) {
    .footer .list-footer a {
      color: #4d161f;
      text-decoration: none;
      margin-right: 20px;
      font-size: 13px;
    }
  }
</style>