import {
  createRouter,
  createWebHistory
} from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import('@/views/home.vue'),
  }

 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  
  },
  routes
})

export default router
