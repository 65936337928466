<template>
  <div class="header">
    <div class="container">
      <img src="/img/logo-aldonza.svg" alt="Logo Aldonza">

      <ul :class="[show ? 'menu-list open' : 'menu-list']">
        <span @click="show = false" class="close-resp"> ✕ </span>
        <li>
          <router-link :to="{name:'home'}">Inicio</router-link>
        </li>
        <li>
          <a href="https://tienda.aldonzagourmet.com/index.php?id_category=2&controller=category" target="_blank" rel="noopener noreferrer">Tienda</a>
        </li>
        <li>
          <a href="https://tienda.aldonzagourmet.com/index.php?id_cms=4&controller=cms" target="_blank" rel="noopener noreferrer">Quiénes somos</a>
        </li>
        <li>
          <a href="https://blog.aldonzagourmet.com/" target="_blank" rel="noopener noreferrer">Blog</a>
        </li>
        <li>
          <a href="https://tienda.aldonzagourmet.com/index.php?controller=contact" target="_blank" rel="noopener noreferrer">Contacto</a>
        </li>
      </ul>
      <img @click="show = true" class="menu-resp" src="/img/menu.svg" alt="logo">
    </div>
  </div>
</template>
<script lang="js">
  export default {
    mounted() {
      this.headDark();
    },
    data: () => ({
      show: false
    }),
    methods: {
      headDark: function () {
        window.onscroll = () => {
          console.log(window.scrollY);
          if (window.scrollY > 40) {
            document.querySelector('.header').classList.add('solid-class')
          } else {
            document.querySelector('.header').classList.remove('solid-class')
          }
        }
      },
    },
    watch: {
      '$route'() {
        this.show = false;
      }
    }
  }
</script>

<style scoped lang="scss">
  .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    border-bottom: 1px solid white;
    z-index: 20;
    transition: all 1s cubic-bezier(0, 1, 0.5, 1);
    box-shadow: none;

    &.solid-class {
      background-color: #4d161f;
      transition: all 1s cubic-bezier(0, 1, 0.5, 1);
      box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.5);
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      img {
        width: 100%;
        max-width: 186px;
      }

      .menu-list {
        width: calc(100% - 735px);
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          margin-left: 15px;

          a {
            text-decoration: none;
            color: white;

            &.router-link-active {
              font-weight: 600;
            }
          }
        }

      }
    }
  }

  .menu-resp,
  .close-resp {
    display: none;
  }

  @media screen and (max-width:1242px) {
    .header .container .menu-list {
      width: calc(100% - 435px);
    }
  }

  @media screen and (max-width:955px) {
    .header .container .menu-list {
      width: calc(100% - 235px);
    }
  }

  @media screen and (max-width:750px) {
    .close-resp {
      font-size: 63px;
      color: white;
      position: absolute;
      top: 0;
      display: inline-block;
      z-index: 9999;
      right: 20px;
      top: 0;

    }

    .header .container img.menu-resp {
      display: flex;
      max-width: 60px;
      width: 100%;
    }

    .header .container .menu-list {
      position: absolute;
      z-index: 20;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 100vh;
      width: 100%;
      background: #4d161f;
      display: flex;
      justify-content: center;
      flex-direction: column;
      transform: translateY(-100vh);
      transition: all 0.8s ease-in-out;


      &.open {
        transform: translateY(0);
        transition: all 0.8s ease-in-out;

      }
    }

    .header .container .menu-list li a {
      text-decoration: none;
      color: white;
      font-size: 25px;
      margin-bottom: 23px;
      display: inline-block;
    }

    .menu-list {
      padding-left: 0;
    }

    .header .container .menu-list li {
      margin-left: 0;
    }
  }
</style>